import {useState, useEffect} from 'react'
import {NavLink} from 'react-router-dom'

import {Button} from '@material-ui/core';

import 'bootstrap/dist/css/bootstrap.css';
import "../index.sass";
import HeaderClass from "./Header.module.sass";

import { Brief } from '../services/constants';
import LogoAnimate from './LogoAnimate/LogoAnimate.tsx';

export const Header = ({logo}) => {
    const [openMenu, setOpenMenu] = useState(false)

    const closeMenu = () => {
        setOpenMenu(false)
    }

    useEffect(
        () => {
            if (openMenu === true)
                document.documentElement.classList.add('ovh')
            else
                document.documentElement.classList.remove('ovh')
        },
        [openMenu]
    );

    return(
        <>
            <header className={HeaderClass['header']} >
                <div className="container-fluid">
                    <div className={HeaderClass['header-container']}>
                        <NavLink className={HeaderClass['logo']} to="/" onClick={() => closeMenu()}>
                            <div className={HeaderClass['logo_image']}>
                                <LogoAnimate/>
                                <img src={logo} alt="FRESCO"/>
                            </div>
                            <span className={HeaderClass['logo_description']}>AI и веб-интегратор</span>
                        </NavLink>

                        <div className={HeaderClass['contacts']}>
                            <a href="tel:+7 (495) 999-49-00" className={HeaderClass['tel']}>+7 (495) 999-49-00</a>
                            <span className={HeaderClass['time']}>9:00 – 18:00</span>
                        </div>

                        <div className={ openMenu ?
                                HeaderClass['mobile_menu__button'] + ' ' + HeaderClass['open']
                                :HeaderClass['mobile_menu__button']}
                            onClick={() => setOpenMenu(!openMenu)}>
                            <div className={HeaderClass['mobile_menu__button__icon']}>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className={HeaderClass['left-bar']}>
                <div className={ openMenu ?
                    HeaderClass['menu__button'] + ' ' + HeaderClass['open']
                    :HeaderClass['menu__button']}
                     onClick={() => setOpenMenu(!openMenu)}>
                    <div className={HeaderClass['menu__button__icon']}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={HeaderClass['text']}>
                        <span>меню</span>
                    </div>
                </div>
            </div>

            <div className={HeaderClass['right-bar']}>

            </div>

            <div className={ openMenu ?
                HeaderClass['menu'] + ' ' + HeaderClass['open']
                :HeaderClass['menu']}>
                <div className={HeaderClass['list']}>
                    <ul>
                        <li>
                            <NavLink exact='true' className={({ isActive }) => isActive ? HeaderClass['active'] : ''} to="/" onClick={() => closeMenu()}>
                                <Button>Главная</Button>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact='true' className={({ isActive }) => isActive ? HeaderClass['active'] : ''} to="/about" onClick={() => closeMenu()}>
                                <Button>Агентство</Button>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact='true' className={({ isActive }) => isActive ? HeaderClass['active'] : ''} to="/projects" onClick={() => closeMenu()}>
                                <Button>Проекты</Button>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact='true' className={({ isActive }) => isActive ? HeaderClass['active'] : ''} to="/services" onClick={() => closeMenu()}>
                                <Button>Услуги</Button>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact='true' className={({ isActive }) => isActive ? HeaderClass['active'] : ''} to="/contacts" onClick={() => closeMenu()}>
                                <Button>Контакты</Button>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className={HeaderClass['contacts']}>
                    <div className={HeaderClass['inner']}>
                        <div>
                            <p>Россия, Томск, ул. Яковлева, 15, офисы «Round», 205</p>
                            <div>
                                <a href="tel:+7 (495) 999-49-00">+7 (495) 999-49-00</a>
                                <a href="mailto:hello@fresco.agency">hello@fresco.agency</a>
                            </div>
                        </div>
                        <div className={HeaderClass['brief_wrapper']}>
                            <a className={'brief'} href={Brief}>
                                <span className={'brief__desc'}>С чего начать работу?</span>
                                <span className={'brief__text'}>Заполните бриф</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}