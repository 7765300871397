import React, { useEffect, useState } from 'react';
import './StoriesView.scss';
import './StoriesView-media.scss';
import { useNavigate } from 'react-router-dom';
import { MediaContent } from '../GlobalFunctions.tsx';
import SliderStoriesGroup from '../SliderStoriesGroup/SliderStoriesGroup.tsx';
import { Props } from './interfaces';

const StoriesView = ({ stories }: Props) => {
  const [view, setView] = useState(false);
  const [numActiveSlide, setNumActiveSlide] = useState(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const path = urlParams.get('s');
    path ? setNumActiveSlide(parseInt(path)) : setNumActiveSlide(0);
  }, []);

  useEffect(() => {
    if (stories.length !== 0) {
      setView(true);
    }
  }, [stories]);

  const navigate = useNavigate();
  const Back = () => navigate(-1);

  const storiesList = stories.length <= 3 ? [...stories, ...stories] : stories;
  
  if (view) {
    return (
      <div className="stories-view">
        {MediaContent(
          <SliderStoriesGroup stories={storiesList} type="main" initialSlide={numActiveSlide - 1} />,
          <SliderStoriesGroup stories={storiesList} type="mobile" initialSlide={numActiveSlide - 1} />
        )}
      </div>
    );
  }

  return (
    <div className="stories-view">
      <div className="stories-view__close" onClick={Back}></div>
    </div>
  );
};

export default StoriesView;
