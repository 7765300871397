import React, {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet'

import AOS from 'aos';
import 'aos/dist/aos.css';

import {NavLink} from 'react-router-dom'
import { Link as AnchorLink} from "react-scroll"
import {getCases, SITE_URL} from '../api'
import "./assets/Projects.sass"
import sprite from "./assets/sprite.symbol.svg"
import link_icon from "./assets/link_icon.svg"
import Tabs from '../components/Tabs/Tabs';
import { ListCategoryCase } from '../components/Tabs/constants';

const Projects = () => {
    AOS.init()

    const [projectsValue, setProjectsValue] = useState([]);
    useEffect(() => {
        getCases().then((data) => setProjectsValue(data.object))
    }, [])


    let headerTitle, bgImage, bgVideo,  SEO_DESCR, SEO_KEYWORDS, TITLE = "Проекты. fresco.agency — AI и веб-интегратор"

    if (Object.entries(projectsValue).length > 0){
        headerTitle = projectsValue.page.headerTitle
        bgImage = projectsValue.page.bgImage
        bgVideo = projectsValue.page.bgVideo

        if (projectsValue.page.SEO_TITLE) {
            TITLE = projectsValue.page.SEO_TITLE
            SEO_DESCR = projectsValue.page.SEO_DESCR
            SEO_KEYWORDS = projectsValue.page.SEO_KEYWORDS
        }
    }

    const [casesList, setCasesList] = useState([]);
    const [caseListFilter, setCaseListFilter] = useState([]);

    useEffect(() => {
      if (Object.entries(projectsValue).length > 0) {
        const casesList = Object.entries(projectsValue.cases);
        setCasesList(casesList);
        setCaseListFilter(casesList);
      }
    }, [projectsValue]);
    

    const FilterProjects = (category) => {
      if (category === ListCategoryCase[0]) {
        setCaseListFilter(casesList);
        return;
      }
      if (category === ListCategoryCase[ListCategoryCase.length - 1]) {// "..." - прочее
        const listFilter = ListCategoryCase.map((x) => x.toLowerCase());
        const list = casesList.filter((x) => !listFilter.includes(x[1].prevCategory?.toLowerCase()));
        setCaseListFilter(list);
        return;
      }

      setCaseListFilter(casesList.filter((x) => x[1].prevCategory?.toLowerCase() === category.toLowerCase()));
    };

    return(
        <>
            <Helmet>
                <title>{ TITLE }</title>
                <meta name="description" content={SEO_DESCR} />
                <meta name="keywords" content={SEO_KEYWORDS} />
            </Helmet>

            <section className="title_video_header">
                <div className="container">
                    <div className="title_video_header__inner">
                        <h1 data-aos="fade-up">{headerTitle}</h1>
                    </div>
                </div>

                <AnchorLink to="home_about" className="arrow_down" smooth={true} offset={-70} duration={500}>
                    <svg className="icon-telegram btn__icon">
                        <use xlinkHref={sprite + "#down-arrow"}></use>
                    </svg>
                </AnchorLink>

                <div className="mobile_background" style={{backgroundImage: `url(${SITE_URL + bgImage})`}}></div>
                <video src={SITE_URL + bgVideo} className="video__bg" width="100%" height="100%" muted="muted" autoPlay="autoplay" loop="loop" poster="" />
            </section>

            <section className='tabs-projects'>
              <Tabs list={ListCategoryCase} onChange={FilterProjects}/>
            </section>

            <section className="projects_list">
                {caseListFilter.map(Case => {
                    if (Case[0].length > 0){
                        if (Case[1].BlocksList){
                            return (
                                <NavLink to={"/case/" + Case[1].id} className="project" data-aos="fade-up" key={Case[1].id}>
                                    <span className="hover">
                                        <span className="category">{Case[1].prevCategory}</span>
                                        <span className="tag_list">
                                            {
                                                Case[1].tagList ?
                                                    Case[1].tagList.split(', ').map((item, key) =>
                                                        <span key={key}>{item}</span>
                                                    )
                                                    :
                                                    ""
                                            }
                                        </span>
                                        <span className="bg" style={{backgroundColor: Case[1].prevColor}}></span>
                                    </span>
                                    <span className="cover" style={{backgroundImage: "url(" + SITE_URL + Case[1].prevImage + ")"}}></span>
                                    <h2 className="name">{Case[1].prevName ? Case[1].prevName : Case[1].headerTitle}</h2>
                                </NavLink>
                            )
                        } else {
                            return (
                                <a href={"http://" + Case[1].headerLink} target="_blank" className="project" data-aos="fade-up" key={Case[1].id}>
                                <span className="hover">
                                    <span className="category">{Case[1].prevCategory}</span>
                                    <span className="tag_list">
                                        {
                                            Case[1].tagList ?
                                                Case[1].tagList.split(', ').map((item, key) =>
                                                    <span key={key}>{item}</span>
                                                )
                                                :
                                                ""
                                        }
                                    </span>
                                    <span className="bg" style={{backgroundColor: Case[1].prevColor}}></span>
                                </span>
                                    <span className="cover" style={{backgroundImage: "url(" + SITE_URL + Case[1].prevImage + ")"}}></span>
                                    <h2 className="name">
                                        {Case[1].prevName ? Case[1].prevName : Case[1].headerTitle}
                                        <div>
                                            <span>
                                                {Case[1].headerLink}
                                                <div style={{background: "url(" + link_icon + ")"}}></div>
                                            </span>
                                        </div>
                                    </h2>

                                </a>
                            )
                        }

                    }
                })}
            </section>
        </>
    )
}

export {Projects}