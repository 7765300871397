import React, {useEffect, useState} from 'react'
import { Helmet } from 'react-helmet'

import AOS from 'aos';
import 'aos/dist/aos.css';

// import {Link, useParams} from 'react-router-dom'
import {NavLink} from 'react-router-dom'
import { Link as AnchorLink} from "react-scroll"
import { Button } from '@material-ui/core';

import {Form} from ".././components/Form"

import 'bootstrap/dist/css/bootstrap.css'
import "../index.sass";
import HomeClass from "./assets/Home.module.sass"

import headerVideo from "./assets/video3.webm"

import sprite from "./assets/sprite.symbol.svg"

import interactive1 from "./assets/interactive1.png"
import interactive2 from "./assets/interactive2.png"
import interactive3 from "./assets/interactive3.png"
import {getMain, SITE_URL} from "../api";

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

const Home = () => {

    AOS.init()

    const [mainValue, setMainValue] = useState([]);

    useEffect(() => {
        getMain().then((data) => setMainValue(data.object))
    }, [])

    let bgImage, SEO_DESCR, SEO_KEYWORDS, TITLE = "fresco.agency — AI и веб-интегратор. Разработка сложных сайтов и сервисов с фокусом на пользователя и бизнес-процессы компании"

    if (Object.entries(mainValue).length > 0){
        bgImage = mainValue.bgImage
        if (mainValue.SEO_TITLE) {
            TITLE = mainValue.SEO_TITLE
            SEO_DESCR = mainValue.SEO_DESCR
            SEO_KEYWORDS = mainValue.SEO_KEYWORDS
        }
    }

    return(
        <>
            <Helmet>
                <title>{ TITLE }</title>
                <meta name="description" content={SEO_DESCR} />
                <meta name="keywords" content={SEO_KEYWORDS} />
            </Helmet>

            <section className={HomeClass["home_header"]}>
                <div className="container">
                    <div className={HomeClass["home_header__inner"]}>

                        <h1 data-aos="fade-up">{htmlToReactParser.parse(mainValue.headerTitle)}</h1>

                        <div className={HomeClass["buttons_line"]} data-aos="fade-up" data-aos-delay="100">

                            <AnchorLink
                                className={HomeClass["btn"]}
                                to="footer_form"
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="btn"
                                    onClick={() => {}}
                                >
                                    <svg><use xlinkHref={sprite + "#telegram"}></use></svg>
                                    <span>Обсудить проект</span>
                                </Button>
                            </AnchorLink>

                            <span>или</span>

                            <NavLink to="/projects" className="italic_link">смотреть работы</NavLink>
                        </div>
                    </div>
                </div>

                <AnchorLink to="home_about" className={HomeClass["arrow_down"]} smooth={true} offset={-70} duration={500}>
                    <svg className="icon-telegram btn__icon">
                        <use xlinkHref={sprite + "#down-arrow"}></use>
                    </svg>
                </AnchorLink>

                <div className={HomeClass["mobile_background"]} style={{backgroundImage: `url(${SITE_URL + bgImage})`}}></div>

                <video className={HomeClass["video__bg"]} width="100%" height="100%" muted="muted" autoPlay="autoplay" loop="loop" poster="">
                    <source src={headerVideo} type="video/mp4" />
                </video>
            </section>

            <section id="home_about" className={HomeClass["home_about"]}>
                <div className="container">
                    <div className="col-2_block">
                        <div className="media" data-aos="fade-in">
                            <div className="wistia_responsive_padding">
                                <div className="wistia_responsive_wrapper">
                                    <div className="wistia_embed wistia_async_e8fzpeaipm seo=false videoFoam=true">&nbsp;</div>
                                </div>
                            </div>
                        </div>

                        <div className="content">
                            <div className="inner">
                                <h2 className="title" data-aos="fade-up">{htmlToReactParser.parse(mainValue.whatWeDoTitle)}</h2>
                                <div className="text" data-aos="fade-up">{htmlToReactParser.parse(mainValue.whatWeDoText)}</div>
                                <NavLink className="italic_link" to={"/" + mainValue.whatWeDoLink} data-aos="fade-up">{htmlToReactParser.parse(mainValue.whatWeDoLinkText)}</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className={HomeClass["home_interactive"]}>
                <div className="container">
                    <div className={HomeClass["home_interactive__inner"]}>
                        <div className={HomeClass["home_interactive__inner__item"]}>
                            <img src={interactive1} alt="Fresco Agency"/>
                        </div>
                        <div className={HomeClass["home_interactive__inner__item"]}>
                            <img src={interactive2} alt="Fresco Agency"/>
                        </div>
                        <div className={HomeClass["home_interactive__inner__item"]}>
                            <img src={interactive3} alt="Fresco Agency"/>
                        </div>
                    </div>
                </div>
            </div>

            <section id="competencies" className={HomeClass["home_about"]}>
                <div className="container">
                    <div className="col-2_block">

                        <div className="content">
                            <div className="inner">
                                <h2 className="title" data-aos="fade-up">{htmlToReactParser.parse(mainValue.сompetenciesTitle)}</h2>
                                <div className="text" data-aos="fade-up">{htmlToReactParser.parse(mainValue.сompetenciesText)} </div>
                                <NavLink className="italic_link" to={"/" + mainValue.сompetenciesLink} data-aos="fade-up">{htmlToReactParser.parse(mainValue.сompetenciesLinkText)}</NavLink>
                            </div>
                        </div>

                        <div className="color_block">
                            <div className="color_block__content" data-aos="bg_animate">
                                <div className="text">
                                    {mainValue.сompetencies ?
                                        <>
                                            <div>
                                                <h3>{htmlToReactParser.parse(mainValue.сompetencies[0].name)}</h3>
                                                <span>{htmlToReactParser.parse(mainValue.сompetencies[0].сompetenciesList["0"].name)}</span>
                                                <span>{htmlToReactParser.parse(mainValue.сompetencies[0].сompetenciesList["1"].name)}</span>
                                                <span>{htmlToReactParser.parse(mainValue.сompetencies[0].сompetenciesList["2"].name)}</span>
                                            </div>
                                            <div>
                                                <h3>{htmlToReactParser.parse(mainValue.сompetencies[1].name)}</h3>
                                                <span>{mainValue.сompetencies[1].сompetenciesList["0"].name}</span>
                                                <span>{mainValue.сompetencies[1].сompetenciesList["1"].name}</span>
                                                <span>{mainValue.сompetencies[1].сompetenciesList["2"].name}</span>
                                                <span>{mainValue.сompetencies[1].сompetenciesList["3"].name}</span>
                                                {/*<span>{mainValue.сompetencies[1].сompetenciesList.["4"].name}</span>*/}
                                            </div>
                                        </>
                                    : ""}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="footer_form">
                <Form />
            </section>
        </>
    )
}

export {Home}