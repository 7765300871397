import React from 'react';
import './StoryIcon.scss';
import './StoryIcon-media.scss';
import { Link } from 'react-router-dom';
import { UrlStories } from '../constants';
import { Props } from './interfaces';

const StoryIcon = ({ image, name, i }: Props) => {
  return (
    <Link to={`${UrlStories}/?s=${i + 1}`} className="story">
      <div style={{ backgroundImage: `url(${image})` }} className="story__image" />
      <p className="story__name">{name}</p>
    </Link>
  );
};

export default StoryIcon;
