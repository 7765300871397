import React from 'react';
import './StoriesBlock.scss';
import './StoriesBlock-media.scss';
import { Props } from './interfaces';
import StoryIcon from '../StoryIcon/StoryIcon.tsx';

const StoriesBlock = ({ stories }: Props) => {
  return (
    <div
      className="stories-block"
      style={stories.length > 12 ? { justifyContent: 'flex-start', paddingBottom: '20px' } : {}}
    >
      {stories.map((item, i) => (
        <StoryIcon i={i} {...item} key={i} />
      ))}
    </div>
  );
};

export default StoriesBlock;
