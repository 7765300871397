export const ListCompetencies = [
  {
    title: 'UX',
    text: 'Умеем правильно исследовать целевую аудиторию и конкурентов, применяем современные инструменты веб-аналитики и статистики для сбора и анализа данных.',
  },
  {
    title: 'UI',
    text: 'Cоздаем интерфейсы с эффектным чистым дизайном, аккуратными анимациями, удобной мобильной версией и расширяемой дизайн-системой.',
  },
  {
    title: 'R&D',
    text: 'Активно включены в digital комьюнити — следим за трендами, хорошо знаем российский digital, общаемся и сотрудничаем с ведущими агентствами России.',
  },
  {
    title: 'UX',
    text: 'Умеем правильно исследовать целевую аудиторию и конкурентов, применяем современные инструменты веб-аналитики и статистики для сбора и анализа данных.',
  },
  {
    title: 'UI',
    text: 'Cоздаем интерфейсы с эффектным чистым дизайном, аккуратными анимациями, удобной мобильной версией и расширяемой дизайн-системой.',
  },
  {
    title: 'R&D',
    text: 'Активно включены в digital комьюнити — следим за трендами, хорошо знаем российский digital, общаемся и сотрудничаем с ведущими агентствами России.',
  },
];
