import React, {Fragment, useEffect, useState} from 'react'
import { Link as AnchorLink} from "react-scroll"

import AOS from 'aos';
import 'aos/dist/aos.css';

import {Form} from ".././components/Form"

import {SITE_URL, getServices} from '../api'

import "./assets/Projects.sass"

import { Parallax } from 'react-parallax';

import sprite from "./assets/sprite.symbol.svg"
import HomeClass from "./assets/Home.module.sass";
import interactive1 from "./assets/interactive1.png";
import interactive2 from "./assets/interactive2.png";
import interactive3 from "./assets/interactive3.png";
import services_paralax_bg from "../images/services_paralax_bg.png";
import {Helmet} from "react-helmet";

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

const Services = () => {

    AOS.init()

    const [Services, setServices] = useState([])

    useEffect(() => {
        getServices().then(data => {setServices(data.object)})
    }, [])

    let headerTitle, bgImage, bgVideo, SEO_DESCR, SEO_KEYWORDS, TITLE = "Услуги. fresco.agency — AI и веб-интегратор"

    if (Object.entries(Services).length > 0){
        headerTitle = Services.headerTitle
        bgImage = Services.bgImage
        bgVideo = Services.bgVideo

        if (Services.SEO_TITLE) {
            TITLE = Services.SEO_TITLE
            SEO_DESCR = Services.SEO_DESCR
            SEO_KEYWORDS = Services.SEO_KEYWORDS
        }
    }

    return(
        <>

            <Helmet>
                <title>{ TITLE }</title>
                <meta name="description" content={SEO_DESCR} />
                <meta name="keywords" content={SEO_KEYWORDS} />
            </Helmet>

            <section className="title_video_header">
                <div className="container">
                    <div className="title_video_header__inner">
                        <h1 data-aos="fade-up">{headerTitle}</h1>
                    </div>
                </div>

                <AnchorLink to="home_about" className="arrow_down" smooth={true} offset={-70} duration={500}>
                    <svg className="icon-telegram btn__icon">
                        <use xlinkHref={sprite + "#down-arrow"}></use>
                    </svg>
                </AnchorLink>

                <div className="mobile_background" style={{backgroundImage: `url(${SITE_URL + bgImage})`}}></div>
                <video src={SITE_URL + bgVideo} className="video__bg" width="100%" height="100%" muted="muted" autoPlay="autoplay" loop="loop" poster="" />
            </section>

            <Parallax className="paralax_400" blur={0} bgImage={services_paralax_bg} bgImageAlt="the cat" strength={-100}></Parallax>

            <section className="services">
                <div className="container">

                    {Services.services ? Services.services.map((ServiceList, index) => {
                        if (Object.entries(ServiceList.servicesList).length > 0){
                            return (
                                <Fragment key={index}>
                                    {index > 0 ?
                                        <div className={HomeClass["home_interactive"] + " interactive"}>
                                            <div className="container">
                                                <div className={HomeClass["home_interactive__inner"]}>
                                                    <div className={HomeClass["home_interactive__inner__item"]}>
                                                        <img src={interactive1} alt="Fresco Agency"/>
                                                    </div>
                                                    <div className={HomeClass["home_interactive__inner__item"]}>
                                                        <img src={interactive2} alt="Fresco Agency"/>
                                                    </div>
                                                    <div className={HomeClass["home_interactive__inner__item"]}>
                                                        <img src={interactive3} alt="Fresco Agency"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ""}
                                    <div className="row">
                                        <div className="col-12">
                                            <h2 className="title bottom-wave mb-0" data-aos="fade-up">{htmlToReactParser.parse(ServiceList.name)}</h2>
                                        </div>

                                        <div className="col-12">
                                            <div className="services_list">
                                                <div className="row">

                                                    {Object.entries(ServiceList.servicesList).map((Service, i) => {
                                                        if (Service[1].image) {
                                                            return (
                                                                <div className="col-6 col-sm-6 col-md-4 col-lg-3 service_card" data-aos="fade-left" data-aos-delay="0" key={i}>
                                                                    <div className="ico">
                                                                      <img src={SITE_URL + Service[1].image} alt="demo"/>
                                                                    </div>
                                                                    <h4>{Service[1].name}</h4>
                                                                    <span>{Service[1].price}</span>
                                                                    <i>{Service[1].text}</i>
                                                                </div>
                                                            )
                                                        }
                                                    })}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        }
                    }) : ''}

                </div>
            </section>

            <Form/>

        </>
    )
}

export {Services}