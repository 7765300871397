import {BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import {Header} from "./components/Header"
import {Footer} from "./components/Footer"
import {ScrollToTop} from "./components/ScrollToTop"

import {Home} from "./pages/Home"
import {Case} from "./pages/Case"
import {About} from "./pages/About"
import {Services} from "./pages/Services"
import {Contacts} from "./pages/Contacts"
import {Projects} from "./pages/Projects"
import {Page404} from "./pages/Page-404"

import logo from './images/logo-2024-2.svg';
import {useEffect} from "react";
import PageStory from './components/Stories/PageStory/PageStory.tsx'

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-cube';

// import Section from "./components/Section/Section";
// import SmoothScroll from "./components/SmoothScroll/SmoothScroll";

function App() {

    useEffect(() => {
        const AOS = require("aos");
        AOS.init({
            once: true,
        });
    }, [])

    return (
            <div className="bodyWrapper">
                <Router>
                    <ScrollToTop />
                    <Header logo={logo}/>
                    <main>
                        <Routes>
                            <Route path="/" element={<Home/>} />
                            <Route path="/home" element={<Home/>} />
                            <Route path="/about" element={<About/>} />
                            <Route path="/contacts" element={<Contacts/>} />
                            <Route path="/projects" element={<Projects/>} />
                            <Route path="/case/:id" element={<Case/>} />
                            <Route path="/services" element={<Services/>} />
                            <Route path="/story" element={<PageStory />} />
                            <Route element={<Page404/>}/>
                        </Routes>
                    </main>
                    <Footer/>
                </Router>
            </div>
    );
}

export default App;