import React from 'react';
import './Competencies.scss';
import './Competencies-media.scss';

const Competencies = ({ list }) => {
  return (
    <div className="container">
      <div className="row justify-content-center competencies">
        <div className="col-12 col-lg-10">
          <h2 className="title bottom-wave mb-0" data-aos="fade-up">
            Наши ключевые компетенции
          </h2>
        </div>

        <div className="col-12 col-xl-10">
          <div className="competencies__list">
            {list.map(({ title, text }, i) => (
              <div className="competencies__item" data-aos="fade-up" key={i}>
                <h4 className="mb-0">{title}</h4>
                <p className="mb-0">{text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Competencies;
