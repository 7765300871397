import React from 'react';
import './LogoAnimate.scss';
import './LogoAnimate-media.scss';

const LogoAnimate = () => {
  return (
    <div className="logo-animate">
      <div className="logo-animate__bg" />
    </div>
  );
};

export default LogoAnimate;
