import React, { useState } from 'react';
import Slider from 'react-slick';
import './Reviews.scss';
import './Reviews-media.scss';
import arrowSlider from '../../images/arrow-slider.svg';
import { listReviews } from './constants';

const Reviews = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const handleAfterChange = (index) => setCurrentSlide(index + 1);

  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={arrowSlider} alt="" />
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={arrowSlider} alt="" />
      </div>
    );
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    fade: true,
    speed: 300,
    slidesToShow: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: handleAfterChange,
    beforeChange: (current, next) => {
      setTimeout(() => handleAfterChange(next), 500);
    },
    className: 'slider-reviews',
    dots: true,
    dotsClass: 'slider-reviews__dots',
    appendDots: (dots) => {
      return (
        <div>
          {`${currentSlide} / `}
          <span>{dots.length}</span>
        </div>
      );
    },
  };

  return (
    <div className="review reviews" data-aos="fade-in" style={{ backgroundColor: '#fff' }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10">
            <h2 className="title bottom-wave mb80" data-aos="fade-up">
              Отзывы клиентов
            </h2>
          </div>

          <div className="col-12 col-xl-10">
            <Slider {...settings}>
              {listReviews.map(({ name, position, site, text, image }, i) => (
                <div key={i}>
                  <div className="reviewWrapper reviews__wrapper">
                    <div className="ava reviews__ava">
                      <div
                        className="reviews__ava-image"
                        style={image ? { backgroundImage: `url(${image})` } : {}}
                      ></div>
                    </div>

                    <div className="text reviews__text">
                      <div className="name">
                        <p className="reviews__name">{name}</p>
                        <p className="reviews__position">
                          {`${position}${position && site ? ', ' : ''}`}
                          {site ? (
                            <a href={`https://${site}`} target="_blank">
                              {site}
                            </a>
                          ) : null}
                        </p>
                      </div>

                      <p dangerouslySetInnerHTML={{ __html: text }}></p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
