import React from 'react';
import Media from 'react-media';

export const MediaContent = (
  contentLarge: React.ReactElement | null,
  contentMedium: React.ReactElement | null
) => {
  return (
    <Media
      queries={{
        medium: 'screen and (max-width: 720px)',
        large: 'screen and (min-width: 721px)',
      }}
    >
      {(matches) => (
        <>
          {matches.medium && contentMedium}
          {matches.large && contentLarge}
        </>
      )}
    </Media>
  );
};
