import './SliderStoriesStoryStub.scss';
import './SliderStoriesStoryStub-media.scss';

const SliderStoriesStoryStub = ({ image, preview }: { image: string; preview: string }) => {
  return (
    <div className="story-stub" style={{ backgroundImage: `url(${preview})` }}>
      <div className="story-stub__head">
        <img src={image} alt="avatar" className="story-stub__avatar" />
      </div>
    </div>
  );
};

export default SliderStoriesStoryStub;
