import React, {useState, useEffect} from 'react'
import {Helmet} from "react-helmet";

import AOS from 'aos';
import 'aos/dist/aos.css';

import { Link as AnchorLink} from "react-scroll"
// import { Parallax } from 'react-parallax';

import {Form} from ".././components/Form"

import {SITE_URL, getAgency} from '../api'

import "slick-carousel/slick/slick.css";
import "bootstrap/dist/css/bootstrap.css"
import "./assets/Projects.sass"

import sprite from "./assets/sprite.symbol.svg"

import team from "../images/team@3x.jpg";

import logo1 from "../images/logo-1.svg"


import Reviews from '../components/Reviews/Reviews';
import Competencies from '../components/Competencies/Competencies';
import { ListCompetencies } from '../components/Competencies/constants';
import HomeInteractive from '../components/HomeInteractive/HomeInteractive';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

const About = () => {

    AOS.init()

    const [Agency, setAgency] = useState([])

    useEffect(() => {
        getAgency().then(data => {setAgency(data.object)})
    }, [])

    let SEO_DESCR, SEO_KEYWORDS, TITLE = "О нас. fresco.agency — AI и веб-интегратор"

    if (Agency.SEO_TITLE) {
        TITLE = Agency.SEO_TITLE
        SEO_DESCR = Agency.SEO_DESCR
        SEO_KEYWORDS = Agency.SEO_KEYWORDS
    }

    

   

    return(
        <>
            <Helmet>
                <title>{ TITLE }</title>
                <meta name="description" content={SEO_DESCR} />
                <meta name="keywords" content={SEO_KEYWORDS} />
            </Helmet>

            <section className="title_video_header">
                <div className="container">
                    <div className="title_video_header__inner">
                        <h1 data-aos="fade-up">{Agency.headerTitle}</h1>
                    </div>
                </div>

                <AnchorLink to="home_about" className="arrow_down" smooth={true} offset={-70} duration={500}>
                    <svg className="icon-telegram btn__icon">
                        <use xlinkHref={sprite + "#down-arrow"}></use>
                    </svg>
                </AnchorLink>

                <div className="mobile_background" style={{backgroundImage: `url(${SITE_URL + Agency.bgImage})`}}></div>
                <video src={SITE_URL + Agency.bgVideo} className="video__bg" width="100%" height="100%" muted="muted" autoPlay="autoplay" loop="loop" poster="" />
            </section>

            <section id="about_about">
                <div className="container">
                    <div className="col-2_block">
                        <div className="media" data-aos="fade-in">
                            <img src={team} alt="" data-aos="fade-up"/>
                        </div>

                        <div className="content">
                            <div className="inner">
                                <h2 className="title" data-aos="fade-up">{Agency.WhoAreWeTitle}</h2>
                                <div className="text" data-aos="fade-up">
                                    {htmlToReactParser.parse(Agency.WhoAreWeText)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <HomeInteractive name="about"/>

            <section id='competencies-24'>
              <Competencies list={ListCompetencies} />
            </section>

            <div id="our_numbers">
                <div className="container">
                    <div className="row justify-content-around">
                        <div className="col-12 col-md-4 col-lg-3 col-xl-2">
                            <h2 data-aos="fade-up">{Agency.col_1_num}</h2>
                            {htmlToReactParser.parse(Agency.col_1_text)}
                        </div>

                        <div className="col-12 col-md-4 col-lg-3 col-xl-2 mt-4 mt-md-0">
                            <h2 data-aos="fade-up">{Agency.col_2_num}</h2>
                            {htmlToReactParser.parse(Agency.col_2_text)}
                        </div>

                        <div className="col-12 col-md-4 col-lg-3 col-xl-2 mt-4 mt-md-0">
                            <h2 data-aos="fade-up">{Agency.col_3_num}</h2>
                            {htmlToReactParser.parse(Agency.col_3_text)}
                        </div>
                    </div>
                </div>
            </div>

            <section id="our_clients">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10">
                            <h2 className="title bottom-wave mb-0" data-aos="fade-up">Наши клиенты</h2>
                        </div>

                        <div className="col-12">
                            <div className="logo_list">
                                <div className="list">
                                    {Agency.logo ?
                                        <>
                                            <div>
                                                <img src={SITE_URL + Agency.logo["0"].image} data-aos="fade-up" alt=""/>
                                                <img src={SITE_URL + Agency.logo["1"].image} data-aos="fade-up" alt=""/>
                                                <img src={SITE_URL + Agency.logo["2"].image} data-aos="fade-up" alt=""/>
                                                <img src={SITE_URL + Agency.logo["3"].image} data-aos="fade-up" alt=""/>
                                                <img src={SITE_URL + Agency.logo["4"].image} data-aos="fade-up" alt=""/>
                                            </div>
                                            <div>
                                                <img src={SITE_URL + Agency.logo["5"].image} data-aos="fade-up" alt=""/>
                                                <img src={SITE_URL + Agency.logo["6"].image} data-aos="fade-up" alt=""/>
                                                <img src={SITE_URL + Agency.logo["7"].image} data-aos="fade-up" alt=""/>
                                                <img src={SITE_URL + Agency.logo["8"].image} data-aos="fade-up" alt=""/>
                                                <img src={SITE_URL + Agency.logo["9"].image} data-aos="fade-up" alt=""/>
                                            </div>
                                            <div>
                                                <img src={SITE_URL + Agency.logo["10"].image} data-aos="fade-up" alt=""/>
                                                <img src={SITE_URL + Agency.logo["11"].image} data-aos="fade-up" alt=""/>
                                                <img src={SITE_URL + Agency.logo["12"].image} data-aos="fade-up" alt=""/>
                                                <img src={SITE_URL + Agency.logo["13"].image} data-aos="fade-up" alt=""/>
                                                <img src={SITE_URL + Agency.logo["14"].image} data-aos="fade-up" alt=""/>
                                            </div>
                                        </>
                                    : "" }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Reviews/>

            <Form/>

        </>
    )
}

export {About}