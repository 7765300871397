import React from 'react';
import './PageStory.scss';
import StoriesView from '../StoriesView/StoriesView.tsx';
import { StoriesList } from '../constants';
import { createPortal } from 'react-dom';

const PageStory = () => {
  return createPortal(
    <div className="page-story">
      <StoriesView stories={StoriesList} />
    </div>,
    document.body
  );
};

export default PageStory;
