import SliderStoriesStoryHead from './SliderStoriesStoryHead/StoriesReactHead.tsx';

const baseURLModx = 'https://test-1010z.fresco.bz';

export const UrlStories='/story'

export const StoriesList = [
  {
    id: '0',
    name: 'title',
    image: `${baseURLModx}/assets/upload/stories/meropriyatiya/preview-meropr.webp`,
    slides: [
      {
        id: 10,
        type: 'image',
        url: `${baseURLModx}/assets/upload/stories/otzivy/stories-otzyv-3.webp`,
        duration: 6000,
        header: (
          <SliderStoriesStoryHead
            name="test"
            url={`${baseURLModx}/assets/upload/stories/otzivy/preview-otzivy.webp`}
          />
        ),
      },
      {
        id: 11,
        type: 'video',
        url: `${baseURLModx}/assets/upload/stories/meropriyatiya/events-1.mp4`,
        duration: 32000,
        header: (
          <SliderStoriesStoryHead
            name="test"
            url={`${baseURLModx}/assets/upload/stories/meropriyatiya/preview-meropr.webp`}
          />
        ),
      },
      {
        id: 12,
        type: 'image',
        url: `${baseURLModx}/assets/upload/stories/otzivy/stories-otzyv-1.webp`,
        duration: 4000,
        header: (
          <SliderStoriesStoryHead
            name="test"
            url={`${baseURLModx}/assets/upload/stories/otzivy/preview-otzivy.webp`}
          />
        ),
      },
      // {
      //   type: 'image',
      //   url: `${baseURLModx}/assets/upload/stories/otzivy/stories-otzyv-3.webp`,
      //   duration: 5000,
      //   header: (
      //     <SliderStoriesStoryHead
      //       name="test2"
      //       url={`${baseURLModx}/assets/upload/stories/otzivy/preview-otzivy.webp`}
      //     />
      //   ),
      // },
    ],
  },
  {
    id: '1',
    name: 'title2',
    image: `${baseURLModx}/assets/upload/stories/meropriyatiya/preview-meropr.webp`,
    slides: [
      {
        id: 20,
        type: 'image',
        url: `${baseURLModx}/assets/upload/stories/otzivy/stories-otzyv-1.webp`,
        duration: 5000,
        header: (
          <SliderStoriesStoryHead
            name="test2"
            url={`${baseURLModx}/assets/upload/stories/otzivy/preview-otzivy.webp`}
          />
        ),
      },
      {
        id: 21,
        type: 'image',
        url: `${baseURLModx}/assets/upload/stories/otzivy/stories-otzyv-3.webp`,
        duration: 5000,
        header: (
          <SliderStoriesStoryHead
            name="test2"
            url={`${baseURLModx}/assets/upload/stories/otzivy/preview-otzivy.webp`}
          />
        ),
      },
    ],
  },
];
