import './SliderStoriesStoryHead.scss';
import './SliderStoriesStoryHead-media.scss';
import { ISliderStoriesStoryHead } from './interfaces';

export const SliderStoriesStoryHead = ({ url, name }: ISliderStoriesStoryHead) => {
  return (
    <div className="head-story">
      <img src={url} alt="Avatar" className="head-story__avatar" />
      <p>{name}</p>
    </div>
  );
};

export default SliderStoriesStoryHead;
