import React from 'react';
import './HomeInteractive.scss';

import HomeClass from '../../pages/assets/Home.module.sass';

import interactive1 from '../../pages/assets/interactive1.png';
import interactive2 from '../../pages/assets/interactive2.png';
import interactive3 from '../../pages/assets/interactive3.png';

const HomeInteractive = ({ name }) => {
  return (
    <div className={`${HomeClass['home_interactive']}${name ? ` home-interactive__${name}` : ''}`}>
      <div className="container">
        <div className={HomeClass['home_interactive__inner']}>
          <div className={HomeClass['home_interactive__inner__item']}>
            <img src={interactive1} alt="Fresco Agency" />
          </div>
          <div className={HomeClass['home_interactive__inner__item']}>
            <img src={interactive2} alt="Fresco Agency" />
          </div>
          <div className={HomeClass['home_interactive__inner__item']}>
            <img src={interactive3} alt="Fresco Agency" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeInteractive;
