import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide, TypeSwiper,EffectCube } from '../ImportsSwiper.ts';
import './SliderStoriesGroup.scss';
import './SliderStoriesGroup-media.scss';
import { Props } from './interfaces';
import { useNavigate } from 'react-router-dom';

import SliderStoriesSingle from '../SliderStoriesSingle/SliderStoriesSingle.tsx';
import StoryStub from '../SliderStoriesStoryStub/SliderStoriesStoryStub.tsx';

const SliderStoriesGroup = ({ stories, type = 'mobile', initialSlide = 0 }: Props) => {
  const [slider, setSlider] = useState<TypeSwiper>();
  const [view, setView] = useState(false);

  useEffect(() => {
    if (stories.length !== 0) {
      setView(true);
    }
  }, [stories]);

  const [numActiveSlide, setNumActiveSlide] = useState(initialSlide);
  const Changed = (current: TypeSwiper) => setNumActiveSlide(current.realIndex + 1);

  const settings = {
    className: 'slider-stories',
    initialSlide,
    slidesPerView: stories.length === 1 ? 1 : 3,
    loop: true,
    centeredSlides: true,
    slideToClickedSlide: true,
    onSlideChange: Changed,
    touchStartPreventDefault: false,
    onInit: (slider: TypeSwiper) => setSlider(slider),
  };
  const settingsMob = {
    ...settings,
    slidesPerView: 1,
    loop: false,
    centeredSlides: false,
    slideToClickedSlide: false,
    effect: 'cube',
    modules: [EffectCube],
  };
  const settingsSlider = type === 'mobile' ? settingsMob : settings;

  const prevStory = () => {
    if (slider) {
      slider.slidePrev(500, false);
    }
  };
  const nextStory = () => {
    if (slider) {
      slider.slideNext(500, false);
    }
  };

  const navigate = useNavigate();
  const Back = () => navigate(-1);

  const storiesList = stories.length <= 3 ? [...stories, ...stories] : stories;

  const [muted, setMuted] = useState(false);
  const ToggleMuted = () => setMuted(!muted);

  if (view) {
    return (
      <div className="slider-stories-group">
        <div className="slider-stories-group__close" onClick={Back}></div>
        <div
          className={`slider-stories-group__volume ${muted ? 'slider-stories-group__volume_off' : ''}`}
          onClick={ToggleMuted}
        />

        <Swiper {...settingsSlider}>
          {storiesList.map(({ slides, image }, i) => (
            <SwiperSlide key={i + image}>
              {type === 'mobile' ? (
                <SliderStoriesSingle
                  stories={slides}
                  prevContent={prevStory}
                  nextContent={nextStory}
                  autoplay={true}
                  active={slider?.realIndex === i}
                  muted={muted}
                />
              ) : i + 1 === numActiveSlide ? (
                <SliderStoriesSingle
                  stories={slides}
                  prevContent={prevStory}
                  nextContent={nextStory}
                  autoplay={true}
                  active={slider?.realIndex === i}
                  muted={muted}
                />
              ) : (
                <StoryStub image={image} preview={slides[0].url} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }

  return (
    <div className="slider-stories-group">
      <div className="slider-stories-group__close" onClick={Back}></div>
    </div>
  );
};

export default SliderStoriesGroup;
