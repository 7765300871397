import React, { useEffect, useRef, useState } from 'react';
import ReactDomServer from 'react-dom/server';
import './SliderStoriesSingle.scss';
import './SliderStoriesSingle-media.scss';
import {
  Swiper,
  SwiperSlide,
  Autoplay,
  Pagination,
  TypeSwiper,
  EffectFade,
  Navigation,
} from '../ImportsSwiper.ts';
import { Props } from './interfaces';

let idTimeout: any;

const SliderStoriesSingle = ({
  stories,
  prevContent = () => {},
  nextContent = () => {},
  autoplay,
  active = true,
  muted,
}: Props) => {
  const [slider, setSlider] = useState<TypeSwiper>();
  const [numActiveSlide, setNumActiveSlide] = useState(0);
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const arrRefs: any = useRef<Array<HTMLVideoElement | null>>([]);
  arrRefs.current = [];

  const Changed = (current: TypeSwiper) => {
    setNumActiveSlide(current.activeIndex);
    setIsStart(current.isBeginning);
    setIsEnd(current.isEnd);
  };

  const settings = {
    className: 'slider-stories-single',
    initialSlide: numActiveSlide,
    slidesPerView: 1,
    loop: false,
    onSlideChange: Changed,
    touchStartPreventDefault: false,
    onInit: (slider: TypeSwiper) => setSlider(slider),
    modules: [EffectFade, Autoplay, Pagination, Navigation],
    effect: 'fade',
    allowTouchMove: false,
    autoplay:
      autoplay && active
        ? {
            disableOnInteraction: false,
            delay: stories[0].duration,
            stopOnLastSlide: true,
          }
        : false,
    onReachEnd: active
      ? () => {
          idTimeout = setTimeout(() => nextParent(), 5000);
        }
      : () => {},
    pagination: active
      ? {
          clickable: true,
          renderBullet: (index: number, className: string) => {
            return ReactDomServer.renderToString(
              <span
                className={`${className} slider-stories-single__dots`}
                style={{ width: `calc((100% - 2px * ${stories.length}) / ${stories.length})` }}
              >
                <span
                  className={`slider-stories-single__dots-indicator`}
                  style={{ animationDuration: `${stories[index].duration / 1000}s` }}
                />
              </span>
            );
          },
        }
      : false,
  };

  useEffect(() => {
    StoppedAllVideo();

    if (!active) {
      return;
    }

    if (!slider?.el.closest('.swiper-slide')?.classList.contains('swiper-slide-active')) {
      return;
    }

    // определить как закрасить пагинацию относительно активного слайда-истории
    slider?.pagination.bullets.forEach((x: HTMLElement, i: number) => {
      const ind = x.querySelector('span');
      if (!ind) {
        return false;
      }

      if (i > numActiveSlide) {
        ind.style.width = 'auto';
        return false;
      }

      ind.style.width = '100%';
    });

    // запустить autoplay для историй
    if (autoplay) {
      slider.autoplay.start();
    }

    // если история-видео, то начать воспроизведение
    if (stories[numActiveSlide].type === 'video') {
      const video = arrRefs.current.find((x: any) => x.idx === numActiveSlide).el;

      if (video) {
        video.play();
      }
      return;
    }

    // если всего один слайд в историях, то после просмотра перейти к следующему блоку историй
    if (stories.length === 1) {
      idTimeout = setTimeout(() => nextParent(), stories[0].duration);
    }
  }, [numActiveSlide, active]);

  const StoppedAllVideo = () => {
    arrRefs.current.forEach(({ el }: { el: HTMLVideoElement | null; idx: number }) => {
      if (el) {
        el.pause();
        el.currentTime = 0;
      }
    });
  };

  const prevStory = () => {
    if (slider) {
      slider.slidePrev(500, false);
    }
  };

  const nextStory = () => {
    if (slider) {
      slider.slideNext(500, false);
    }
  };

  // переход к следующему блоку историй
  const prevParent = () => {
    prevContent();
    clearTimeout(idTimeout);
  };

  // переход к предыдущему блоку историй
  const nextParent = () => {
    nextContent();
    clearTimeout(idTimeout);
  };

  const [timeLeftPause, setTimeLeftPause] = useState(6000);
  const [paused, setPaused] = useState(false);
  const TouchPause = () => {
    if (!slider) {
      return;
    }

    // зафиксировать время, на котором поставили паузу
    setTimeLeftPause(slider.autoplay.timeLeft);
    setPaused(true);
    // визуально остановить анимацию на пагинации
    slider.pagination.bullets.forEach((x: HTMLElement) => {
      (x.querySelector('.slider-stories-single__dots-indicator') as HTMLElement).style.animationPlayState =
        'paused';
    });

    slider.autoplay.pause();
    // если история-видео, то остановить воспроизведение
    if (stories[numActiveSlide].type === 'video') {
      const video = arrRefs.current.find((x: any) => x.idx === numActiveSlide).el;

      if (video) {
        video.pause();
      }
    }

    // если это последняя история в блоке то сбросить запущенный таймер для перехода к следующему блоку историй
    if (isEnd) {
      clearTimeout(idTimeout);
    }
  };
  const TouchContinue = () => {
    if (!slider) {
      return;
    }

    setPaused(false);
    // визуально продолжить анимацию на пагинации
    slider.pagination.bullets.forEach((x: HTMLElement) => {
      (x.querySelector('.slider-stories-single__dots-indicator') as HTMLElement).style.animationPlayState =
        'running';
    });

    slider.autoplay.resume();
    // если история-видео, то продолжить воспроизведение
    if (stories[numActiveSlide].type === 'video') {
      const video = arrRefs.current.find((x: any) => x.idx === numActiveSlide).el;

      if (video) {
        video.play();
      }
    }

    // если это последняя история в блоке то снова запустить таймер для перехода к следующему блоку историй на оставшийся промежуток времени (timeLeftPause, сохраненный при паузе)
    if (isEnd) {
      idTimeout = setTimeout(() => nextParent(), timeLeftPause);
    }
  };

  return (
    <Swiper {...settings}>
      {stories.map(({ type, url, duration, header }, i) => (
        <SwiperSlide key={i} data-swiper-autoplay={duration}>
          <div className="slider-stories-single__slide">
            <div
              className={`slider-stories-single__header${
                paused ? ' slider-stories-single__header_hide' : ''
              }`}
            >
              {header}
            </div>
            {type === 'video' ? (
              <video
                ref={(el) => {
                  if (el) {
                    arrRefs.current.push({ el, idx: i });
                  }
                }}
                src={url}
                className="slider-stories-single__content"
                onEnded={isEnd ? nextParent : nextStory}
                muted={muted}
                controls={false}
                onTouchStart={TouchPause}
                onTouchEnd={TouchContinue}
                onContextMenu={(e) => e.preventDefault()}
              />
            ) : (
              <img
                src={url}
                className="slider-stories-single__content"
                alt=""
                onTouchStart={TouchPause}
                onTouchEnd={TouchContinue}
                onContextMenu={(e) => e.preventDefault()}
              />
            )}
          </div>
        </SwiperSlide>
      ))}

      <div className="slider-stories-single__prev" onClick={isStart ? prevParent : prevStory} />
      <div
        className="slider-stories-single__next"
        onClick={isEnd || stories.length === 1 ? nextParent : nextStory}
      />
    </Swiper>
  );
};

export default SliderStoriesSingle;
